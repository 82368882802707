import Service from '@/helpers/service'

export default {

    sync(params) {
        return Service().get('integration/sap/sync', {params});
    },
    execute(id) {
        return Service().get('integration/sap/'+id+'?force=1');
    }
}